import React, { Component } from "react" // eslint-disable-line no-unused-vars
import { navigate } from "gatsby"


class IronCAPBountyContestMain extends Component {
  

	componentDidMount() {
  		navigate("/ironcap-bountycontest/en");
    	return null
  	}


	render() {
		
		return <React.Fragment />
	}
  
}

export default IronCAPBountyContestMain
